var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map"},[_c('GmapMap',{ref:"map",attrs:{"center":_vm.mapCenter,"zoom":_vm.mapZoom,"options":{
           mapTypeControlOptions: this.mapTypeControlOptions,
           scaleControl: false,
           streetViewControl: true,
           rotateControl: false,
           fullscreenControl: false,
           disableDefaultUi: true,
           zoomControlOptions: this.zoomControlOptions,
           styles: this.mapStyles,
           maxZoom: this.$store.state.maxZoom,
           minZoom: 10
        }},on:{"dragstart":function($event){return _vm.stopAutoCenter()}}},[_c('GmapCluster',{attrs:{"enableRetinaIcons":true,"zoomOnClick":true,"imagePath":"//www.landschaphorstaandemaas.nl/images/markers/m"}},[_vm._l((_vm.visibleMarkers),function(marker){return _c('GmapMarker',{key:marker.id,attrs:{"position":{lat: parseFloat(marker.locationLat), lng: parseFloat(marker.locationLng)},"animation":_vm.animateMarker(marker),"icon":_vm.getIcon(marker),"zIndex":1,"title":marker.title},on:{"click":function($event){return _vm.showMarker(marker)}}})}),_vm._l((_vm.segments),function(segment,index){return _c('GmapPolyline',{key:index,attrs:{"path":segment,"options":{'strokeColor': '#007E48'}}})})],2),_vm._l((_vm.visibleJunctions),function(junction){return _c('GmapMarker',{key:junction.id,attrs:{"clickable":false,"position":{
                lat: parseFloat(junction.lat),
                lng: parseFloat(junction.lng)
            },"label":{
                text : ''+junction.junction,
                color : 'white',
                fontSize: '10px'
            },"icon":_vm.getJunctionIcon(),"zIndex":10}})})],2),(this.$store.state.isMapLoading)?_c('div',{staticClass:"loading-route"},[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v("Bezig met laden... ")]):_vm._e(),_c('div',{staticClass:"link-cover"}),_c('a',{staticClass:"btn btn-primary",class:{'no-focus' : !_vm.autoCenter},attrs:{"href":"","title":"Toon mijn locatie","id":"location-button"},on:{"click":function($event){$event.preventDefault();return _vm.showLocation()}}},[(_vm.autoCenter)?_c('span',[_c('i',{staticClass:"fa fa-location-circle"})]):_c('span',[_vm._v("Centreren "),_c('i',{staticClass:"fa fa-location-circle"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }