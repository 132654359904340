<template>
    <div class="map">
        <GmapMap
            ref="map"
            :center="mapCenter"
            :zoom="mapZoom"
            :options="{
               mapTypeControlOptions: this.mapTypeControlOptions,
               scaleControl: false,
               streetViewControl: true,
               rotateControl: false,
               fullscreenControl: false,
               disableDefaultUi: true,
               zoomControlOptions: this.zoomControlOptions,
               styles: this.mapStyles,
               maxZoom: this.$store.state.maxZoom,
               minZoom: 10
            }"
            @dragstart="stopAutoCenter()"
        >
            <GmapCluster
                :enableRetinaIcons="true"
                :zoomOnClick="true"
                imagePath="//www.landschaphorstaandemaas.nl/images/markers/m"
            >
                <GmapMarker
                    v-for="marker in visibleMarkers"
                    :key="marker.id"
                    :position="{lat: parseFloat(marker.locationLat), lng: parseFloat(marker.locationLng)}"
                    :animation="animateMarker(marker)"
                    :icon="getIcon(marker)"
                    @click="showMarker(marker)"
                    :zIndex="1"
                    :title="marker.title"
                />

                <GmapPolyline
                    v-for="(segment, index) in segments"
                    :key="index"
                    :path="segment"
                    :options="{'strokeColor': '#007E48'}"
                >
                </GmapPolyline>
            </GmapCluster>
            <GmapMarker
                v-for="junction in visibleJunctions"
                :key="junction.id"
                :clickable="false"
                :position="{
                    lat: parseFloat(junction.lat),
                    lng: parseFloat(junction.lng)
                }"
                :label="{
                    text : ''+junction.junction,
                    color : 'white',
                    fontSize: '10px'
                }"
                :icon="getJunctionIcon()"
                :zIndex="10"
            />
        </GmapMap>
        <div class="loading-route" v-if="this.$store.state.isMapLoading">
            <i class="fas fa-spinner fa-spin">
            </i>Bezig met laden...
        </div>
        <div class="link-cover"></div>
        <a
            class="btn btn-primary"
            :class="{'no-focus' : !autoCenter}"
            href=""
            title="Toon mijn locatie"
            id="location-button"
            @click.prevent="showLocation()"
        >
            <span v-if="autoCenter"><i class="fa fa-location-circle"></i></span>
            <span v-else>Centreren <i class="fa fa-location-circle"></i></span>
        </a>
    </div>
</template>

<script>
/* global google */
//import GmapMarkerWithLabel from './markerWithLabel.vue'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { gmapApi } from 'vue2-google-maps';
import { mapGetters } from 'vuex';

export default {
    name: "Map",
    components: { GmapCluster },
    data: () => ({
        currentLocation: null,
        currentLocationMarker: null,
        mapCenter: { lat: 51.45058222407976, lng: 6.026711007864036 },
        mapZoom: 12,
        labelAnchor: null,
        zoomControlOptions: null,
        mapTypeControlOptions: null,
        isInitialized: false,
        autoCenter: false,
        showPopup: false,
        mapStyles: [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -75
                    },
                    {
                        "lightness": 10
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#8eafb2"
                    },
                    {
                        "lightness": 50
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ]
    }),
    watch: {
        visibleMarkers: function(){
            this.$refs.map.$mapPromise.then((map) => {
                if(this.showPopup){
                    const currentMarker = this.visibleMarkers.find(marker => marker.slug == this.$route.params.markerSlug);
                    if(currentMarker){
                        this.$store.dispatch('selectMarker', currentMarker);
                        this.showPopup = false;
                    }
                }
                this.fitBounds(map);
            })
        },
        segments: function(){
            this.$refs.map.$mapPromise.then((map) => {
                this.fitBounds(map);
            })
        }
    },
    mounted () {
        this.$refs.map.$mapPromise.then((map) => {
            this.fitBounds(map);
            this.zoomControlOptions = {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            }
            this.mapTypeControlOptions = {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.TOP_RIGHT
            }

            // Manually create and update the current location marker,
            // Because if we let Vue handle this, the map keeps refreshing on Chrome/Android devices.
            this.currentLocationMarker = new google.maps.Marker({
                icon: {
                        url: 'https://www.landschaphorstaandemaas.nl/images/markers/user.png?v=2',
                        size: new google.maps.Size(345, 345),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(60, 60),
                        scaledSize: new google.maps.Size(120, 120)
                    },
                map: null
            })
            this.geolocation();
            if(this.$route.query.showPopup && this.$route.query.showPopup == 'true' && this.$route.params.markerSlug ){
                this.showPopup = true;
            }
        })
    },
    beforeUnmount() {
        if (this.geoWatchFunction) {
            navigator.geolocation.clearWatch(this.geoWatchFunction);
        }
    },
    computed: {
        google: gmapApi,
        segments () {
            let visibleSegments = this.visibleRoutes.flatMap(r => r.path);
            return visibleSegments
        },
        ...mapGetters(['visibleMarkers', 'visibleRoutes', 'visibleJunctions'])
    },
    methods: {
        showMarker(marker){
            this.$store.dispatch('selectMarker', marker);
        },
        fitBounds(map){
            const bounds = new google.maps.LatLngBounds();
            let boundsSet = false;
            if(this.visibleMarkers.length > 0 ){
                for (let m of this.visibleMarkers) {
                    bounds.extend(new google.maps.LatLng(m.locationLat, m.locationLng))
                }
                boundsSet = true;
            }
            if(this.segments.length > 0 ){
                for (let s of this.segments) {
                    for (let p of s){
                        bounds.extend(p);
                    }
                }
                boundsSet = true;
            }
            if(boundsSet && this.visibleMarkers.length > 1){
                map.fitBounds(bounds);
                setTimeout(() => google.maps.event.trigger(map, 'zoom_changed'), 5);
            }else if(this.visibleMarkers.length == 1){
                map.fitBounds(bounds);
                this.mapZoom = 15;
            }
        },
        animateMarker(marker){
            if(this.$store.state.selectedMarker){
                if(marker.id == this.$store.state.selectedMarker.id){
                    return 1;
                }
                return 0;
            }else{
                return 0;
            }
        },
        getIcon(marker){
            let icon = {
                url: marker.icon,
                size: new google.maps.Size(62, 100),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 50),
                scaledSize: new google.maps.Size(31, 50)
            };
            return icon;
        },
        getJunctionIcon(){
            let icon = {
                url: 'https://www.landschaphorstaandemaas.nl/images/markers/knooppunt.png?v=3',
                size: new google.maps.Size(100, 100),
                scaledSize: new google.maps.Size(20,20),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(10, 10),
                labelOrigin: new google.maps.Point(10, 10)
            };
            return icon;
        },
        geolocation : function() {

            const geoLocationOptions = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0,
            }

			this.geoWatchFunction = navigator.geolocation.watchPosition((position) => {
                this.currentLocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

                // Update position of marker manually
                this.currentLocationMarker.setPosition({lat: position.coords.latitude, lng: position.coords.longitude});
                this.currentLocationMarker.setMap(this.$refs.map.$mapObject) // Make sure it's visible

                if(this.autoCenter == true){
                    this.centerMapToLocation();
                }
            }, (err) => {
                console.error(`ERROR(${err.code}): ${err.message}`);
            }, geoLocationOptions);
        },
        showLocation : function() {
            this.autoCenter = true
            if(this.currentLocation){
                this.centerMapToLocation();
            }else{
                this.autoCenter = false;
                alert('We kunnen je locatie helaas niet ophalen')
            }

        },
        centerMapToLocation: function(){
            if(this.currentLocation){
                this.mapCenter = this.currentLocation
                this.$refs.map.$mapPromise.then((map) => {
                    map.setCenter(this.mapCenter);
                })
            }
        },
        stopAutoCenter: function() {
            this.autoCenter = false;
        }
    }
}
</script>

<style scoped>

</style>
