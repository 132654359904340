import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// Omdat we verschillende Vue instances hebben tussen de app en de website, laden we in dit geval de store via een factory method
export default function (Vue, mode, base) {
    Vue.use(VueRouter)

    const routes = [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {category: 'specialplaces'}
        }, {
            path: '/wandelroutes',
            name: 'Walkingroutes',
            component: Home,
            meta: {category: 'walkingroutes'}
        }, {
            path: '/fietsroutes',
            name: 'Bikeroutes',
            component: Home,
            meta: {category: 'bikeroutes'}
        }, {
            path: '/petranpad',
            name: 'Petranroutes',
            component: Home,
            meta: {category: 'petranroutes'}
        },
        {
            path: '/bijzondere-plekken/:categorySlug',
            name: 'MarkerCategory',
            component: Home,
            meta: {category: 'specialplaces'}
        },
        {
            path: '/bijzondere-plekken/:categorySlug/:markerSlug',
            name: 'Marker',
            component: Home,
            meta: {category: 'specialplaces'}
        }, {
            path: '/wandelroutes/:routeSlug',
            name: 'Walkingroute',
            component: Home,
            meta: {category: 'walkingroutes'}
        }, {
            path: '/fietsroutes/:routeSlug',
            name: 'Bikeroute',
            component: Home,
            meta: {category: 'bikeroutes'}
        }, {
            path: '/petranpad/:routeSlug',
            name: 'Petranroute',
            component: Home,
            meta: {category: 'petranroutes'}
        }
    ]

    return new VueRouter({
        mode: mode,
        base: base,
        routes
    })
}
