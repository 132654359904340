import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import routerFactory from './router'
import storeFactory from './store'
import vuetify from './plugins/vuetify';
import './plugins/vue2-google-maps';
import VueGtag from 'vue-gtag'
import '@fortawesome/fontawesome-pro/css/all.css';

const store = storeFactory(Vue);
const router = routerFactory(Vue, 'history', process.env.BASE_URL);

Vue.config.productionTip = false

console.log('%cLandschap Horst aan de Maas%c App\r\n' +
    '%cVersion: %c' + process.env.VUE_APP_VERSION + '\r\n' +
    '%cAPI: %c' + process.env.VUE_APP_API_URL + '\r\n' +
    //'%cLanguage: %c' + i18n.locale + ' %c(' + navigator.languages + ') \r\n'+
    '%cDeveloped by: %cInternetbureau Forwart%c - %chttps://www.forwart.nl/',
    'color: #007E48; font-weight: bold; font-size: 1.3em;',
    'color: #000000; font-size: 1.3em;',
    'color: #888', 'color: #999;',
    'color: #888', 'color: #999;',
    //'color: #888', 'color: #28358D;', 'color: #ccc',
    'color: #888', 'color: #ec2e24; font-weight: bold', 'color: #333', 'color: #00f; text-decoration: underline;'
);

Vue.use(VueGtag, {
    config: {
        id: 'G-GZ6R43F1PC'
    }
}, router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
