import Vuex from 'vuex'
import axios from 'axios';

// Omdat we verschillende Vue instances hebben tussen de app en de website, laden we in dit geval de store via een factory method
export default function (vueInstance) {
    vueInstance.use(Vuex)

    return new Vuex.Store({
        state: {
            apiUrl: null,
            mode: null,

            showMarkers: true,
            showBikeRoutes: true,
            showWalkRoutes: true,
            showPetranRoutes: true,

            markers: [],
            categories: [],
            routes: [],

            visibleCategories: [],
            visibleRouteIds: [],

            selectedMarker: null,
            selectedRoute: null,

            extraVisibleMarkerIds: [],

            showFilters: true,
            isMapLoading:false,

            maxZoom: 22,
        },
        mutations: {
            INITIALIZE(state, {apiUrl, mode}) {
                state.apiUrl = apiUrl
                state.mode = mode;
            },
            TOGGLE_MARKERS(state, isVisible) {
                state.showMarkers = isVisible
            },
            TOGGLE_BIKE_ROUTES(state, isVisible) {
                state.showBikeRoutes = isVisible
            },
            TOGGLE_WALK_ROUTES(state, isVisible) {
                state.showWalkRoutes = isVisible
            },
            TOGGLE_PETRAN_ROUTES(state, isVisible) {
                state.showPetranRoutes = isVisible
            },
            SET_MARKERS(state, markers) {
                state.markers = markers;
            },
            SET_CATEGORIES(state, categories) {
                state.categories = categories;
            },
            SET_ROUTES(state, routes) {
                state.routes = routes;
            },
            SET_SELECTED_MARKER(state, marker) {
                state.selectedMarker = marker;
            },
            SET_SELECTED_ROUTE(state, route) {
                state.selectedRoute = route;
            },
            UPDATE_ROUTE(state, route) {
                let index = state.routes.findIndex(r => r.id === route.id);
                state.routes[index] = route;
            },
            TOGGLE_CATEGORY(state, {categoryId, visible}) {
                if (visible) {
                    state.visibleCategories.push(categoryId);
                } else {
                    state.visibleCategories = state.visibleCategories.filter(c => c !== categoryId)
                }
            },
            TOGGLE_MARKER(state, {markerId, visible}) {
                if (visible) {
                    state.extraVisibleMarkerIds.push(markerId);
                } else {
                    state.extraVisibleMarkerIds = state.extraVisibleMarkerIds.filter(m => m !== markerId)
                }
            },
            TOGGLE_ROUTE(state, {id, visible}) {
                if (visible) {
                    state.visibleRouteIds.push(id);
                } else {
                    state.visibleRouteIds = state.visibleRouteIds.filter(c => c !== id)
                }
            },
            TOGGLE_SHOW_FILTERS(state, {visible}){
                state.showFilters = visible;
            },
            TOGGLE_MAP_LOADING(state, {visible}){
                state.isMapLoading = visible;
            },
            TOGGLE_MAX_ZOOM(state, {zoom}){
                state.maxZoom = zoom;
            }
        },
        actions: {
            initialize({commit, dispatch}, {apiUrl, mode, activeRoute}) {
                commit('INITIALIZE', {apiUrl, mode})

                return Promise.all([
                    dispatch('loadMarkers'),
                    dispatch('loadCategories'),
                    dispatch('loadRoutes'),
                ]).then(() => {
                    dispatch('selectDataFromRouter', { route: activeRoute })
                });
            },
            selectDataFromRouter({ dispatch, state, commit }, { route }) {
                if (route.category === 'bikeroutes') {
                    // check of er een routeSlug in de route zag
                    if (route.routeSlug) {

                        // zoek de betreffende route
                        const bikeRoute = state.routes.find(r => (r.slug === route.routeSlug) && (r.category === 'bike'));
                        if (bikeRoute) {
                            // Laad de extra data
                            dispatch('loadRoute', { id: bikeRoute.id}).then((route) => {
                                // En toon hem
                                dispatch('toggleRoute', { id: route.id, visible: true })
                            })
                        }
                    }
                }
                if (route.category === 'walkingroutes') {
                    // check of er een routeSlug in de route zag
                    if (route.routeSlug) {
                        // zoek de betreffende route
                        const walkRoute = state.routes.find(r => (r.slug === route.routeSlug) && (r.category === 'walk'));
                        if (walkRoute) {
                            // Laad de extra data
                            dispatch('loadRoute', { id: walkRoute.id}).then((route) => {
                                // En toon hem
                                dispatch('toggleRoute', { id: route.id, visible: true })
                            })
                        }
                    }
                }
                if (route.category === 'petranroutes') {
                    // check of er een routeSlug in de route zag
                    if (route.routeSlug) {
                        // zoek de betreffende route
                        const petranRoute = state.routes.find(r => (r.slug === route.routeSlug) && (r.category === 'petran'));
                        if (petranRoute) {
                            // Laad de extra data
                            dispatch('loadRoute', { id: petranRoute.id}).then((route) => {
                                // En toon hem
                                dispatch('toggleRoute', { id: route.id, visible: true })
                            })
                        }
                    }
                }
                if (route.category === 'specialplaces') {
                    if (route.categorySlug) {
                        const category = state.categories.find(c => c.slug === route.categorySlug);

                        if (category) {
                            if (route.markerSlug) {
                                //commit('TOGGLE_MAX_ZOOM', {zoom: 15});
                                const marker = state.markers.find(m => (m.slug === route.markerSlug));
                                commit('TOGGLE_MARKER', {markerId: marker.id, visible: true});
                            } else {
                                dispatch('toggleCategory', { categoryId: category.id, visible: true })
                            }
                        }
                    }
                }

            },
            toggleCategory({commit, state}, { categoryId, visible }) {
                commit('TOGGLE_CATEGORY', { categoryId: categoryId, visible })
                state.categories.forEach(c => {
                    if (c.parent === categoryId) {
                        commit('TOGGLE_CATEGORY', { categoryId: c.id, visible })
                    }
                })
            },
            loadMarkers({commit, state}) {
                return axios
                    .get(state.apiUrl + '/markers.json')
                    .then(response => {
                        commit('SET_MARKERS', response.data);
                    })
            },
            loadCategories({commit, state}) {
                return axios
                    .get(state.apiUrl + '/categories.json')
                    .then(response => {
                        commit('SET_CATEGORIES', response.data);
                    })
            },
            loadRoutes({commit, state}) {
                return axios
                    .get(state.apiUrl + '/routes.json')
                    .then(response => {
                        commit('SET_ROUTES', response.data);
                    })
            },
            selectMarker({commit, state}, marker) {
                commit('SET_SELECTED_MARKER', marker);
                return axios
                    .get(state.apiUrl + '/markers/' + marker.id + '.json')
                    .then(response => {
                        commit('SET_SELECTED_MARKER', response.data);
                    })
            },
            clearSelectedMarker({commit}) {
                commit('SET_SELECTED_MARKER', null);
            },
            clearSelectedRoute({commit}) {
                commit('SET_SELECTED_ROUTE', null);
            },
            selectRoute({commit, dispatch}, route) {
                commit('SET_SELECTED_ROUTE', route);
                return dispatch('loadRoute', {id: route.id})
                    .then(route => {
                        commit('SET_SELECTED_ROUTE', route);
                    })
            },
            toggleRoute({commit, dispatch, state}, {id, visible}) {
                commit('TOGGLE_ROUTE', {id, visible});
                commit('TOGGLE_MAP_LOADING', {visible : true});
                if (visible) {
                    // zoek route op in local store
                    let route = state.routes.find(r => r.id === id);

                    // als route (path) nog niet bekend is, dan ophalen via API
                    if (!route.path) {
                        return dispatch('loadRoute', {id}).then(() => {
                            commit('TOGGLE_ROUTE', {id, visible});
                            commit('TOGGLE_MAP_LOADING', {visible : false});
                        });
                    }else{
                        commit('TOGGLE_MAP_LOADING', {visible : false});
                    }
                }else{
                    commit('TOGGLE_MAP_LOADING', {visible : false});
                }
            },
            loadRoute({state, commit}, {id}) {
                return axios
                    .get(state.apiUrl + '/routes/' + id + '.json')
                    .then(response => {
                        commit('UPDATE_ROUTE', response.data);
                        return response.data;
                    })
            },
        },
        getters: {
            visibleMarkers(state, getters) {
                let visibleMarkerIds = [];

                if (state.showMarkers) {
                    visibleMarkerIds = state.markers.filter(m => (state.visibleCategories.includes(m.category) || state.extraVisibleMarkerIds.includes(m.id)))
                        .map(m => m.id);
                }

                let routes = getters.visibleRoutes;
                visibleMarkerIds = routes.map(r => r.pois)
                    .reduce((acc, routePois) => {
                        return [...acc, ...routePois.map(j => j.id)];
                    }, visibleMarkerIds)

                return state.markers.filter(m => visibleMarkerIds.includes(m.id));
            },
            visibleJunctions (state, getters) {
                let routes = getters.visibleRoutes;
                return routes.map(r => r.junctions)
                    .reduce((acc, routeJunctions) => {
                        return [...acc, ...routeJunctions];
                    }, [])
            },
            visibleRoutes(state) {
                return state.routes
                    .filter(r => state.visibleRouteIds.includes(r.id))
                    .filter(r => r.path) // alleen dingen die al een path hebben
                    .filter(r => {
                        if (r.category == 'walk') {
                            return state.showWalkRoutes;
                        } else if(r.category == 'bike'){
                            return state.showBikeRoutes;
                        } else{
                            return state.showPetranRoutes;
                        }
                    })
            },
            categoryTree(state) {
                let rootCategories = state.categories.filter(c => c.parent === null);

                rootCategories.forEach(c => {
                    c.children = state.categories.filter(sc => sc.parent === c.id);
                })

                return rootCategories;
            },
            bikeRoutes(state) {
                return state.routes.filter(route => route.category === 'bike');
            },
            walkRoutes(state) {
                return state.routes.filter(route => route.category === 'walk');
            },
            petranRoutes(state) {
                return state.routes.filter(route => route.category === 'petran');
            }
        }
    })

}
