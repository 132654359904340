<template>
    <div class="app-container">
        <MarkerInfo v-if="selectedMarker && mode == 'app'"></MarkerInfo>
        <RouteInfo v-if="selectedRoute && mode == 'app'"></RouteInfo>
        <MarkerInfoBootstrap v-if="selectedMarker && mode == 'website'"></MarkerInfoBootstrap>
        <RouteInfoBootstrap v-if="selectedRoute && mode == 'website'"></RouteInfoBootstrap>
        <Map></Map>
        <Filters :category="category"></Filters>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Map from "./Map";
import Filters from "./Filters";
import MarkerInfo from "./MarkerInfo";
import MarkerInfoBootstrap from "./MarkerInfoBootstrap";
import RouteInfo from "./RouteInfo";
import RouteInfoBootstrap from "./RouteInfoBootstrap";

export default {
    name: "PointsOfInterest",
    components: {MarkerInfo, RouteInfo, Filters, Map, MarkerInfoBootstrap, RouteInfoBootstrap},
    computed: {
        category() {
            //return 'specialplaces';
            return this.$route.meta.category;
        },
        ...mapState(['apiUrl', 'mode', 'selectedMarker', 'selectedRoute'])
    },
}
</script>

<style scoped>

</style>
