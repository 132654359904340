<template>
    <div class="side-nav" :class="{ 'active' : showFilters }">
        <a href="#" class="hide-filters" title="Sluit filters" @click.prevent="toggleFilters()"><i class="fa fa-chevron-right"></i></a>
        <div class="filters">
            <div class="filter-wrapper">
                <div class="category" v-if="category === 'specialplaces'">
                    <label>
                        Mooie plekken
                    </label>
                    <ul>
                        <li v-for="category in categoryTree" :key="category.id">
                            <input type="checkbox"
                                   :checked="isRootChecked(category.id) || isChildChecked(category.id)"
                                   @input="toggleRoot(category.id, $event.target.checked)"
                                   :id="'marker_category-filter-' + category.id"
                            />
                            <label :for="'marker_category-filter-' + category.id" :style="{'background-color': category.color}" :class="{ 'has-children': category.children.length > 0}">
                                <i class="fa fa-square" v-if="!isRootChecked(category.id) && !isChildChecked(category.id)"></i>
                                <i class="fa fa-check" v-if="isRootChecked(category.id) || isChildChecked(category.id)"></i>
                                <span class="label-text-content">{{category.title}}</span>
                                <span class="badge">{{ getCategoryMarkerCount(category) }}</span>
                            </label>
                            <a @click.prevent="" title="Toon meer filters" class="toggle-filters" v-if="category.children.length > 0"><i class="fa fa-chevron-down"></i></a>
                            <ul v-bind:class="{ active: isRootChecked(category.id) || isChildChecked(category.id) }" v-if="category.children.length > 0">
                                <li v-for="subcategory in category.children" :key="subcategory.id">
                                    <input type="checkbox"
                                           :checked="isChildChecked(subcategory.id)"
                                           @input="toggleChild(subcategory.id, $event.target.checked)"
                                           :id="'subcategory-filter-' + subcategory.id"
                                    />
                                    <label :for="'subcategory-filter-' + subcategory.id">
                                        <i class="fa fa-square" v-if="!isChildChecked(subcategory.id)"></i>
                                        <i class="fa fa-check-square" v-if="isChildChecked(subcategory.id)"></i>
                                        {{subcategory.title}}
                                        <span class="badge">{{ getSubcategoryMarkerCount(subcategory) }}</span>
                                    </label>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="category" v-if="category === 'walkingroutes'">
                    <label class="route-header">Wandelroutes</label>
                    <div class="route-filters" v-cloak>
                        <div class="filter-container show" v-if="mode === 'app'">
                            <div class="filter">
                                <v-slider
                                    :max="maxWalkRouteLength"
                                    :min="minWalkRouteLength"
                                    label="Minimale afstand"
                                    thumb-label
                                    v-model="selectedWalkRouteMinLength"
                                ></v-slider>
                            </div>
                            <div class="filter">
                                <v-slider
                                    :max="maxWalkRouteLength"
                                    :min="minWalkRouteLength"
                                    label="Maximale afstand"
                                    thumb-label
                                    v-model="selectedWalkRouteMaxLength"
                                ></v-slider>
                            </div>
                        </div>
                        <div class="filter-container show" v-if="mode === 'website' && walkRoutes.length >= 1">
                            <div class="filter filter-bootstrap">
                                <p class="filter-title">Minimale afstand</p>
                                <vue-slider
                                    :max="maxWalkRouteLength"
                                    :min="minWalkRouteLength"
                                    v-model="selectedWalkRouteMinLength"
                                />
                            </div>
                            <div class="filter filter-bootstrap">
                                <p class="filter-title">Maximale afstand</p>
                                <vue-slider
                                    :max="maxWalkRouteLength"
                                    :min="minWalkRouteLength"
                                    v-model="selectedWalkRouteMaxLength"
                                />
                            </div>
                        </div>
                    </div>
                    <ul class="detailed-view">
                        <li v-for="route in filteredWalkRoutes" :key="route.id" :class="{ 'is-active' : isRouteChecked(route.id) }">
                            <input type="checkbox"
                                   :id="'walk-route-' + route.id"
                                   :checked="isRouteChecked(route.id)"
                                   @input="toggleRoute(route.id, $event.target.checked)"
                            />
                            <label :for="'walk-route-' + route.id">
                                <i class="fa fa-square" v-if="!isRouteChecked(route.id)"></i>
                                <i class="fa fa-check" v-if="isRouteChecked(route.id)"></i>
                                {{ route.title }}<br />
                                <span v-if="route.displayLength">{{ route.displayLength }}</span>
                                <span v-else>{{ route.length.replace('.',',').replace(',00','') }} km</span>
                            </label>
                            <div class="bottom">
                                <a href="#" @click.prevent="selectRoute(route)" title="meer informatie">Informatie <i class="fa fa-info fa-sm ml-2"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="category" v-if="category === 'bikeroutes'">
                    <label class="route-header">Fietsroutes </label>
                    <div class="route-filters" v-cloak>
                        <div class="filter-container show" v-if="mode === 'app'">
                            <div class="filter">
                                <v-slider
                                    :max="maxBikeRouteLength"
                                    :min="minBikeRouteLength"
                                    label="Minimale afstand"
                                    thumb-label
                                    v-model="selectedBikeRouteMinLength"
                                ></v-slider>
                            </div>
                            <div class="filter">
                                <v-slider
                                    :max="maxBikeRouteLength"
                                    :min="minBikeRouteLength"
                                    label="Maximale afstand"
                                    thumb-label
                                    v-model="selectedBikeRouteMaxLength"
                                ></v-slider>
                            </div>
                        </div>
                        <div class="filter-container show" v-if="mode === 'website' && bikeRoutes.length >= 1">
                            <div class="filter filter-bootstrap">
                                <p class="filter-title">Minimale afstand</p>
                                <vue-slider
                                    :max="maxBikeRouteLength"
                                    :min="minBikeRouteLength"
                                    v-model="selectedBikeRouteMinLength"
                                />
                            </div>
                            <div class="filter filter-bootstrap">
                                <p class="filter-title">Maximale afstand</p>
                                <vue-slider
                                    :max="maxBikeRouteLength"
                                    :min="minBikeRouteLength"
                                    v-model="selectedBikeRouteMaxLength"
                                />
                            </div>
                        </div>
                    </div>
                    <ul class="detailed-view">
                        <li v-for="route in filteredBikeRoutes" :key="route.id" :class="{ 'is-active' : isRouteChecked(route.id) }">
                            <input type="checkbox"
                                   :id="'bike-route-' + route.id"
                                   :checked="isRouteChecked(route.id)"
                                   @input="toggleRoute(route.id, $event.target.checked)"
                            />
                            <label :for="'bike-route-' + route.id">
                                <i class="fa fa-square" v-if="!isRouteChecked(route.id)"></i>
                                <i class="fa fa-check" v-if="isRouteChecked(route.id)"></i>
                                {{ route.title }}<br />
                                <span v-if="route.displayLength">{{ route.displayLength }}</span>
                                <span v-else>{{ route.length.replace('.',',').replace(',00','') }} km</span>
                            </label>
                            <div class="bottom">
                                <a href="#" @click.prevent="selectRoute(route)" title="meer informatie">Informatie <i class="fa fa-info fa-sm ml-2"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="category" v-if="category === 'petranroutes'">
                    <label class="route-header">Petranpad</label>
                    <div class="route-filters" v-cloak>
                        <div class="filter-container show" v-if="mode === 'app'">
                            <div class="filter">
                                <v-slider
                                    :max="maxPetranLength"
                                    :min="minPetranLength"
                                    label="Minimale afstand"
                                    thumb-label
                                    v-model="selectedPetranMinLength"
                                ></v-slider>
                            </div>
                            <div class="filter">
                                <v-slider
                                    :max="maxPetranLength"
                                    :min="minPetranLength"
                                    label="Maximale afstand"
                                    thumb-label
                                    v-model="selectedPetranMaxLength"
                                ></v-slider>
                            </div>
                        </div>
                        <div class="filter-container show" v-if="mode === 'website' && walkRoutes.length >= 1">
                            <div class="filter filter-bootstrap">
                                <p class="filter-title">Minimale afstand</p>
                                <vue-slider
                                    :max="maxPetranLength"
                                    :min="minPetranLength"
                                    v-model="selectedPetranMinLength"
                                />
                            </div>
                            <div class="filter filter-bootstrap">
                                <p class="filter-title">Maximale afstand</p>
                                <vue-slider
                                    :max="maxPetranLength"
                                    :min="minPetranLength"
                                    v-model="selectedPetranMaxLength"
                                />
                            </div>
                        </div>
                    </div>
                    <ul class="detailed-view">
                        <li v-for="route in filteredPetranRoutes" :key="route.id" :class="{ 'is-active' : isRouteChecked(route.id) }">
                            <input type="checkbox"
                                   :id="'petranpad-' + route.id"
                                   :checked="isRouteChecked(route.id)"
                                   @input="toggleRoute(route.id, $event.target.checked)"
                            />
                            <label :for="'petranpad-' + route.id">
                                <i class="fa fa-square" v-if="!isRouteChecked(route.id)"></i>
                                <i class="fa fa-check" v-if="isRouteChecked(route.id)"></i>
                                {{ route.title }}<br />
                                <span v-if="route.displayLength">{{ route.displayLength }}</span>
                                <span v-else>{{ route.length.replace('.',',').replace(',00','') }} km</span>
                            </label>
                            <div class="bottom">
                                <a href="#" @click.prevent="selectRoute(route)" title="meer informatie">Informatie <i class="fa fa-info fa-sm ml-2"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import VueSlider from 'vue-slider-component/dist/vue-slider-component.common.js';

export default {
    name: "Filters",
    components: {
        VueSlider
    },
    data: () => ({
        selectedBikeRouteMinLength: 0,
        selectedBikeRouteMaxLength: 0,
        selectedWalkRouteMinLength: 0,
        selectedWalkRouteMaxLength: 0,
        selectedPetranMinLength: 0,
        selectedPetranMaxLength: 0,
        showBikeFilters: false,
        showWalkFilters: false,
        showPetranFilters: false,
        expandedRootCategories: [],
    }),
    props: {
        category: {
            type: String,
            required: true
        }
    },
    computed: {
        showMarkers: {
            get () { return this.$store.state.showMarkers; },
            set (newValue) { this.$store.commit('TOGGLE_MARKERS', newValue); }
        },
        showBikeRoutes: {
            get () { return this.$store.state.showBikeRoutes; },
            set (newValue) { this.$store.commit('TOGGLE_BIKE_ROUTES', newValue); }
        },
        showWalkRoutes: {
            get () { return this.$store.state.showWalkRoutes; },
            set (newValue) { this.$store.commit('TOGGLE_WALK_ROUTES', newValue); }
        },
        showPetranRoutes: {
            get () { return this.$store.state.showPetranRoutes; },
            set (newValue) { this.$store.commit('TOGGLE_PETRAN_ROUTES', newValue); }
        },
        categoryTree() {
            return this.$store.getters.categoryTree
        },
        bikeRoutes() {
            return this.$store.getters.bikeRoutes
        },
        walkRoutes() {
            return this.$store.getters.walkRoutes
        },
        petranRoutes() {
            return this.$store.getters.petranRoutes
        },
        filteredWalkRoutes() {
            return this.walkRoutes.filter(r => r.length >= this.selectedWalkRouteMinLength && r.length <= this.selectedWalkRouteMaxLength);
        },
        filteredBikeRoutes() {
            return this.bikeRoutes.filter(r => r.length >= this.selectedBikeRouteMinLength && r.length <= this.selectedBikeRouteMaxLength);
        },
        filteredPetranRoutes() {
            return this.petranRoutes.filter(r => r.length >= this.selectedPetranMinLength && r.length <= this.selectedPetranMaxLength);
        },
        maxWalkRouteLength() {
            return Math.max.apply(Math, this.walkRoutes.map(function(o) { return parseFloat(o.length); }));
        },
        maxBikeRouteLength() {
            return Math.max.apply(Math, this.bikeRoutes.map(function(o) { return parseFloat(o.length); }));
        },
        maxPetranLength() {
            return Math.max.apply(Math, this.petranRoutes.map(function(o) { return parseFloat(o.length); }));
        },
        minWalkRouteLength() {
            return Math.min.apply(Math, this.walkRoutes.map(function(o) { return parseFloat(o.length); }));
        },
        minBikeRouteLength() {
            return Math.min.apply(Math, this.bikeRoutes.map(function(o) { return parseFloat(o.length); }));
        },
        minPetranLength() {
            return Math.min.apply(Math, this.petranRoutes.map(function(o) { return parseFloat(o.length); }));
        },
        showFilters() {
            return this.$store.state.showFilters;
        },
        ...mapState(['mode'])
    },
    watch: {
        bikeRoutes: function(){
            this.selectedBikeRouteMinLength = this.minBikeRouteLength;
            this.selectedBikeRouteMaxLength = this.maxBikeRouteLength;
            this.selectedWalkRouteMinLength = this.minWalkRouteLength;
            this.selectedWalkRouteMaxLength = this.maxWalkRouteLength;
            this.selectedPetranMinLength = this.minPetranLength;
            this.selectedPetranMaxLength = this.maxPetranLength;
        }
    },
    methods: {
        isRootChecked(categoryId) {
            return this.$store.state.categories.find(c => {
                if (c.parent === categoryId) {
                    if (this.isChildChecked(c.id)) {
                        return true;
                    }
                }
                return false
            })
        },
        isChildChecked(categoryId) {
            return this.$store.state.visibleCategories.includes(categoryId);
        },
        isMarkersChecked(){
            return this.$store.state.showMarkers;
        },
        isBikeRoutesChecked(){
            return this.$store.state.showBikeRoutes;
        },
        isWalkRoutesChecked(){
            return this.$store.state.showWalkRoutes;
        },
        isPetranRoutesChecked(){
            return this.$store.state.showPetranRoutes;
        },
        isRouteChecked(id){
            return this.$store.state.visibleRouteIds.includes(id);
        },
        toggleRoot(categoryId, visible) {
            this.$store.commit('TOGGLE_MAX_ZOOM', {zoom: 22});
            this.$store.dispatch('toggleCategory', { categoryId, visible })
        },
        toggleChild(categoryId, visible) {
            this.$store.commit('TOGGLE_MAX_ZOOM', {zoom: 22});
            this.$store.commit('TOGGLE_CATEGORY', { categoryId, visible })
        },
        toggleRoute(id, visible) {
            this.$store.commit('TOGGLE_MAX_ZOOM', {zoom: 22});
            this.$store.dispatch('toggleRoute', { id, visible })
        },
        toggleShowBikeFilters(){
            this.showBikeFilters = !this.showBikeFilters
        },
        toggleShowWalkFilters(){
            this.showWalkFilters = !this.showWalkFilters
        },
        toggleShowPetranFilters(){
            this.showPetranFilters = !this.showPetranFilters
        },
        getSubcategoryMarkerCount(subcategory) {
            return this.$store.state.markers.filter(m => m.category === subcategory.id).length;
        },
        getCategoryMarkerCount(subcategory) {
            let mainCategoryMarkers = this.$store.state.markers.filter(m => m.category === subcategory.id).length;
            let categoryIds = this.$store.state.categories.filter(c => c.parent === subcategory.id).map(c => c.id);

            return this.$store.state.markers.filter(m => categoryIds.includes(m.category)).length + mainCategoryMarkers;
        },
        selectRoute(route) {
            this.$store.commit('TOGGLE_MAX_ZOOM', {zoom: 22});
            this.$store.dispatch('selectRoute', route);
        },
        toggleFilters(){
            this.$store.commit('TOGGLE_SHOW_FILTERS', {visible: !this.$store.state.showFilters})
        }
    }
}
</script>

<style scoped>

</style>
