<template>
    <b-modal v-model="dialog" id="modal-1" size="xl" hide-header hide-footer>
        <div
            class="headline"
            :style="{
                    'background-color': marker.color,
                    color: '#fff',
                    position: 'relative'
                }"
        >
            {{ marker.categoryTitle }}
            <a
                href="#"
                @click.prevent="hideMarker()"
            >
                <i class="fa fa-times"></i>
            </a>
        </div>

        <div v-if="marker.modal" class="marker-info-text">
            <div class="row">
                <div class="d-flex flex-column" :class="{
                    'col-md-6' : !marker.text || (marker.text && marker.text.length < 1200),
                    'col-md-8' : marker.text && marker.text.length >= 1200
                }">
                    <div class="general-info flex-grow-1">
                        <p v-if="marker.title" class="marker-description">{{ marker.title }}<span v-if="marker.city">{{ marker.city }}</span></p>
                        <div v-if="marker.info" v-html="marker.info" class="mb-3"></div>
                        <p v-if="marker.summary">{{ marker.summary }}</p>
                        <div v-if="marker.text && marker.text.length < 1200" v-html="marker.text"></div>
                    </div>
                    <div class="row buttons flex-grow-0 pt-3" v-if="marker.url || marker.file">
                        <div class="col-md-6">
                            <a class="btn btn-primary" v-if="marker.file" color="primary" :href="marker.file.url" target="_blank"><i class="fa fa-file-alt mr-2 fa-sm"></i> Brochure</a>
                        </div>
                    </div>
                </div>
                <div :class="{
                    'col-md-6' : !marker.text || (marker.text && marker.text.length < 1200),
                    'col-md-4' : marker.text && marker.text.length >= 1200
                }">
                    <div class="images" v-if="marker.images && marker.images.length > 0">
                        <VueSlickCarousel v-bind="sliderSettings">
                            <a :href="image.largeUrl" data-fancybox="marker" :data-thumb="image.url" v-for="image in marker.images" :key="image.id">
                                <img :src="image.url" />
                            </a>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="extended-text" v-if="marker.text && marker.text.length >= 1200" v-html="marker.text">

            </div>
            <div class="website-button pt-5 d-flex justify-content-end"><a class="btn btn-primary" :href="'https://www.landschaphorstaandemaas.nl/' + marker.websiteSlug">Alle informatie <i class="fa fa-chevron-right ml-2 fa-sm"></i></a></div>

        </div>
        <div class="info-window-loading" v-else>
            <i class="fas fa-spinner fa-spin">
            </i>Bezig met laden...
        </div>
        <div class="poi-id" v-if="marker.id">#{{ marker.id }}</div>
    </b-modal>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BModal } from 'bootstrap-vue';
import "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
    name: "MarkerInfoBootstrap",
    components: { VueSlickCarousel, BModal },
    data () {
      return {
          dialog: true,
          sliderSettings: {
              "autoplay": true,
              "arrows": true,
              "dots": false,
              "infinite": true,
              "pauseOnHover": true,
              "speed": 500,
              "slidesToShow": 1,
              "slidesToScroll": 1
          }
      }
    },
    watch: {
        dialog (){
            if(this.dialog == false){
                setTimeout(() => this.$store.dispatch('clearSelectedMarker'), 500);
            }
        }
    },
    computed: {
        marker () {
            return this.$store.state.selectedMarker;
        }
    },
    methods: {
        hideMarker (){
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

</style>
