import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import nl from 'vuetify/es5/locale/nl'

export default new Vuetify({
    lang: {
        locales: {nl},
        current: 'nl'
    },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#007E48',
        secondary: '#7EB79A',
        offwhite: '#f0f0f0',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
    icons: {
        iconfont: 'fa',
        values: {
            sort: 'fal fa-long-arrow-up ml-1',
            prev: 'fal fa-chevron-left',
            next: 'fal fa-chevron-right',
            dropdown: 'fal fa-chevron-down',
            expand: 'fas fa-chevron-down',
        },
    },

});
