<template>
    <v-dialog v-model="dialog" max-width="1000px" width="auto">
        <v-card>
            <v-card-title
                class="headline"
                :style="{
                    'background-color': marker.color,
                    color: '#fff',
                    position: 'relative'
                }"
            >
                {{ marker.categoryTitle }}
                <v-btn
                    color="white"
                    fab
                    small
                    absolute
                    right
                    @click="hideMarker()"
                >
                    <v-icon>fa fa-times</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <div v-if="marker.modal" class="marker-info-text">
                    <v-row>
                        <v-col class="d-flex flex-column" :class="{
                            'col-md-6' : !marker.text || (marker.text && marker.text.length < 1200),
                            'col-md-8' : marker.text && marker.text.length >= 1200
                        }">
                            <div class="general-info flex-grow-1 mb-4">
                                <p v-if="marker.title" class="marker-description">{{ marker.title }}<span v-if="marker.city">{{ marker.city }}</span></p>
                                <div v-if="marker.info" v-html="marker.info" class="mb-3"></div>
                                <p v-if="marker.summary">{{ marker.summary }}</p>
                                <div v-if="marker.text && marker.text.length < 1200" v-html="marker.text"></div>
                            </div>
                            <v-row class="buttons flex-grow-0 pt-3" v-if="marker.url || marker.file">
                                <v-col class="col-md-6">
                                    <v-btn v-if="marker.file" color="primary" :href="marker.file.url" target="_blank" depressed><v-icon class="mr-2" small>fa fa-file-alt</v-icon> Brochure</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <div :class="{
                            'col-md-6' : !marker.text || (marker.text && marker.text.length < 1200),
                            'col-md-4' : marker.text && marker.text.length >= 1200
                        }">
                            <div class="images" v-if="marker.images && marker.images.length > 0">
                                <VueSlickCarousel v-bind="sliderSettings">
                                    <a :href="image.largeUrl" data-fancybox="marker" :data-thumb="image.url" v-for="image in marker.images" :key="image.id">
                                        <img :src="image.url" />
                                    </a>
                                </VueSlickCarousel>
                            </div>
                        </div>
                    </v-row>
                    <div class="extended-text" v-if="marker.text && marker.text.length >= 1200" v-html="marker.text">

                    </div>
                    <v-flex class="mt-5">
                        <v-btn color="primary" target="_blank" :href="'https://www.landschaphorstaandemaas.nl/' + marker.websiteSlug" depressed>Alle informatie<v-icon class="ml-2" small>fa fa-chevron-right</v-icon></v-btn>
                    </v-flex>
                </div>
                <div class="info-window-loading" v-else>
                    <i class="fas fa-spinner fa-spin">
                    </i>Bezig met laden...
                </div>
            </v-card-text>
            <div class="poi-id" v-if="marker.id">#{{ marker.id }}</div>
        </v-card>
    </v-dialog>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
    name: "MarkerInfo",
    components: { VueSlickCarousel },
    data () {
      return {
          dialog: true,
          sliderSettings: {
              "autoplay": true,
              "arrows": true,
              "dots": false,
              "infinite": true,
              "pauseOnHover": true,
              "speed": 500,
              "slidesToShow": 1,
              "slidesToScroll": 1,
              fancybox: null,
          }
      }
    },
    watch: {
        dialog (){
            if(this.dialog == false){
                setTimeout(() => this.$store.dispatch('clearSelectedMarker'), 500);
            }
        }
    },
    computed: {
        marker () {
            return this.$store.state.selectedMarker;
        }
    },
    methods: {
        hideMarker (){
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

</style>
