<template>
    <v-dialog v-model="dialog" max-width="1000px" width="auto">
        <v-card>
            <v-card-title
                class="headline"
                :style="{
                    'background-color': route.color,
                    color: '#fff',
                    position: 'relative'
                }"
            >
              {{ route.title }}
                <v-btn
                    color="white"
                    fab
                    small
                    absolute
                    right
                    @click="hideRoute()"
                >
                    <v-icon>fa fa-times</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <div v-if="route.modal" class="marker-info-text">
                    <v-row class="row">
                        <v-col class="d-flex flex-column w-100-print" :class="{
                            'col-md-6' : !route.description || (route.description && route.description.length < 1200),
                            'col-md-8' : route.description && route.description.length >= 1200
                        }">
                            <div class="general-info flex-grow-0">
                                <p v-if="route.category && route.category == 'bike'" class="marker-description">
                                    Fietsroute
                                    <span v-if="route.displayLength">{{ route.displayLength }}</span>
                                    <span v-else>{{ route.length.replace('.',',').replace(',00','') }} km</span>
                                </p>
                                <p v-if="route.category && route.category == 'walk'" class="marker-description">
                                    Wandelroute
                                </p>
                                <p v-if="route.category && route.category == 'petran'" class="marker-description">
                                    Petranpad
                                    <span v-if="route.displayLength">{{ route.displayLength }}</span>
                                    <span v-else>{{ route.length.replace('.',',').replace(',00','') }} km</span>
                                </p>
                                <div class="logo" v-if="route.logo">
                                    <img :src="route.logo.url" />
                                </div>
                                <p v-if="route.startName"><strong>{{ route.startName }}</strong></p>
                                <div v-if="route.description && route.description.length < 1200" v-html="route.description"></div>
                            </div>
                            <v-expansion-panels accordion>
                                <v-expansion-panel class="hide-on-print">
                                    <v-expansion-panel-header><i class="fa fa-file-alt mr-3"></i>Kaart en info</v-expansion-panel-header>
                                    <v-expansion-panel-content :eager="true">
                                        <div class="buttons flex-grow-0" v-if="route.docs && route.docs.length > 0">
                                            <div v-for="doc in route.docs" :key="doc.filename" >
                                                <v-btn class="mb-2" color="offwhite" :href="doc.url" small target="_blank" depressed><v-icon class="mr-2" small>fa fa-file-alt</v-icon> {{ doc.filename }}</v-btn>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel v-if="route.junctions && route.junctions.length > 0">
                                    <v-expansion-panel-header><i class="fa fa-map mr-3 "></i>Knooppunten</v-expansion-panel-header>
                                    <v-expansion-panel-content :eager="true">
                                        <div class="junctions-wrapper">
                                            <div class="pois pois-junctions flex-grow-0">
                                                <div class="junction-container" v-for="junction in route.junctions" :key="route.id + '-' + junction.junction + '-' + Math.floor(Math.random() * 1000)">
                                                    <span class="junction">{{ junction.junction }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel v-if="route.pois && route.pois.length > 0">
                                    <v-expansion-panel-header><i class="fa fa-map-marked-alt mr-3 "></i>Bezienswaardigheden</v-expansion-panel-header>
                                    <v-expansion-panel-content :eager="true">
                                        <div class="pois flex-grow-0">
                                            <div v-for="poi in route.pois" :key="poi.name">
                                                <span class="poi" :style="{'background-color': poi.color}" @click="showPoi(poi)"><img :src="poi.icon" v-if="poi.icon" />{{ poi.name }}</span>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col :class="{
                            'col-md-6' : !route.description || (route.description && route.description.length < 1200),
                            'col-md-4' : route.description && route.description.length >= 1200
                        }">
                            <div class="images hide-on-print" v-if="route.images && route.images.length > 0">
                                <VueSlickCarousel v-bind="sliderSettings">
                                    <img v-for="image in route.images" :key="image.id" :src="image.url" />
                                </VueSlickCarousel>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="extended-text" v-if="route.description && route.description.length >= 1200" v-html="route.description">

                    </div>
                    <v-flex class="pt-5">
                        <v-btn color="primary" target="_blank" :href="'https://www.landschaphorstaandemaas.nl/' + route.websiteSlug" depressed>Alle informatie<v-icon class="ml-2" small>fa fa-chevron-right</v-icon></v-btn>
                    </v-flex>
                    <div class="route-buttons pt-5 mt-5 d-flex hide-on-print" :class="{
                            'justify-content-between' : route.gpx,
                            'justify-content-end' : !route.gpx,
                        }">
                        <v-btn v-if="route.gpx" color="primary" :href="route.gpx.url" target="_blank" depressed><v-icon class="mr-2" small>fa fa-download</v-icon> Download GPX</v-btn>
                        <v-btn color="primary" onclick="window.print();return false;" depressed><v-icon class="mr-2" small>fa fa-print</v-icon> Afdrukken</v-btn>
                        <v-btn v-if="route.path" color="primary" @click.prevent="toggleRoute(route.id, true)" depressed><v-icon class="mr-2" small>fa fa-map-marked-alt</v-icon> Toon Route</v-btn>
                    </div>
                </div>
                <div class="info-window-loading" v-else>
                    <i class="fas fa-spinner fa-spin">
                    </i>Bezig met laden...
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export default {
    name: "RouteInfo",
    components: { VueSlickCarousel },
    data () {
      return {
          dialog: true,
          sliderSettings: {
              "autoplay": true,
              "arrows": true,
              "dots": false,
              "infinite": true,
              "pauseOnHover": true,
              "speed": 500,
              "slidesToShow": 1,
              "slidesToScroll": 1
          }
      }
    },
    watch: {
        dialog (){
            if(this.dialog == false){
                setTimeout(() => this.$store.dispatch('clearSelectedRoute'), 500);
            }
        }
    },
    computed: {
        route () {
            return this.$store.state.selectedRoute;
        }
    },
    methods: {
        hideRoute (){
            this.dialog = false;
        },
        toggleRoute(id, visible) {
            this.hideRoute(),
            this.$store.dispatch('toggleRoute', { id, visible })
        },
        showPoi(poi){
            this.$store.dispatch('selectMarker', poi);
        },
    }
}
</script>

<style scoped>

</style>
