<template>
    <b-modal v-model="dialog" id="modal-1" size="xl" hide-header hide-footer>
        <div
            class="headline"
            :style="{
                    'background-color': route.color,
                    color: '#fff',
                    position: 'relative'
                }"
        >
            {{ route.title }}
            <a
                href="#"
                @click.prevent="hideRoute()"
            >
                <i class="fa fa-times"></i>
            </a>
        </div>

        <div v-if="route.modal" class="marker-info-text">
            <div class="row">
                <div class="d-flex flex-column" :class="{
                            'col-md-6' : !route.description || (route.description && route.description.length < 1200),
                            'col-md-8' : route.description && route.description.length >= 1200
                        }">
                    <div class="general-info flex-grow-0">
                        <p v-if="route.category && route.category == 'bike'" class="marker-description">
                            Fietsroute
                            <span v-if="route.displayLength">{{ route.displayLength }}</span>
                            <span v-else>{{ route.length.replace('.',',').replace(',00','') }} km</span>
                        </p>
                        <p v-if="route.category && route.category == 'walk'" class="marker-description">
                            Wandelroute
                        </p>
                        <p v-if="route.category && route.category == 'petran'" class="marker-description">
                            Petranpad
                        </p>
                        <p v-if="route.startName"><strong>{{ route.startName }}</strong></p>
                        <div v-if="route.description && route.description.length < 1200" v-html="route.description"></div>
                        <div class="logo" v-if="route.logo">
                            <img :src="route.logo.url" />
                        </div>
                    </div>
                    <div id="accordion" class="popup-docs">

                        <div v-if="route.docs && route.docs.length > 0" class="card mb-3">
                            <div class="card-header" id="files-heading">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFiles" aria-expanded="true" aria-controls="collapseFiles">
                                    <i class="fa fa-file-alt mr-3"></i> Kaart en info <i class="fa fa-chevron-down ml-auto"></i>
                                </button>
                            </div>
                            <div id="collapseFiles" class="collapse" aria-labelledby="files-heading" data-parent="#accordion">
                                <div class="card-body">
                                    <div class="buttons flex-grow-0">
                                        <div v-for="doc in route.docs" :key="doc.filename" class="mb-2">
                                            <a class="btn btn-secondary btn-sm" color="offwhite" :href="doc.url" small target="_blank"><i class="fa fa-file-alt fa-sm mr-2"></i> {{ doc.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="route.junctions && route.junctions.length > 0" class="card mb-3">
                            <div class="card-header" id="junctions-heading">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseJunctions" aria-expanded="true" aria-controls="collapseJunctions">
                                    <i class="fa fa-map mr-3 "></i> Knooppunten <i class="fa fa-chevron-down ml-auto"></i>
                                </button>
                            </div>
                            <div id="collapseJunctions" class="collapse" aria-labelledby="junctions-heading" data-parent="#accordion">
                                <div class="card-body">
                                    <div class="pois">
                                        <div v-for="junction in route.junctions" :key="route.id + '-' + junction.junction + '-' + Math.floor(Math.random() * 1000)">
                                            <span class="mb-2">{{ junction.junction }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="route.pois && route.pois.length > 0" class="card">
                            <div class="card-header" id="pois-heading">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsePois" aria-expanded="true" aria-controls="collapsePois">
                                    <i class="fa fa-map-marked-alt mr-3 "></i> Bezienswaardigheden <i class="fa fa-chevron-down ml-auto"></i>
                                </button>
                            </div>
                            <div id="collapsePois" class="collapse" aria-labelledby="pois-heading" data-parent="#accordion">
                                <div class="card-body">
                                    <div class="pois">
                                        <div v-for="poi in route.pois" :key="poi.id">
                                            <span class="poi" :style="{'background-color': poi.color}" @click="showPoi(poi)"><img :src="poi.icon" v-if="poi.icon" />{{ poi.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div :class="{
                            'col-md-6' : !route.description || (route.description && route.description.length < 1200),
                            'col-md-4' : route.description && route.description.length >= 1200
                        }">
                    <div class="images" v-if="route.images && route.images.length > 0">
                        <VueSlickCarousel v-bind="sliderSettings">
                            <img v-for="image in route.images" :key="image.id" :src="image.url" />
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="extended-text" v-if="route.description && route.description.length >= 1200" v-html="route.description">

            </div>
            <div class="pt-5">
                <a class="btn btn-primary" :href="'https://www.landschaphorstaandemaas.nl/' + route.websiteSlug">Alle informatie <i class="fa fa-chevron-right ml-2 fa-sm"></i></a>
            </div>
            <div class="route-buttons pt-5 mt-5 d-flex" :class="{
                            'justify-content-between' : route.gpx,
                            'justify-content-end' : !route.gpx,
                        }">
                <a v-if="route.gpx" class="btn btn-primary" :href="route.gpx.url" target="_blank"><i class="fa fa-download mr-2 fa-sm"></i> Download GPX</a>
                <a class="btn btn-primary" onclick="window.print();return false;" href="#"><i class="fa fa-print mr-2 fa-sm"></i> Afdrukken</a>
                <a v-if="route.path" class="btn btn-primary" href="#" @click.prevent="toggleRoute(route.id, true)"><i class="mr-2 fa fa-map-marked-alt fa-sm"></i> Toon Route</a>
            </div>
        </div>
        <div class="info-window-loading" v-else>
            <i class="fas fa-spinner fa-spin">
            </i>Bezig met laden...
        </div>
    </b-modal>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BModal } from 'bootstrap-vue';

export default {
    name: "RouteInfoBootstrap",
    components: { VueSlickCarousel, BModal },
    data () {
      return {
          dialog: true,
          sliderSettings: {
              "autoplay": true,
              "arrows": true,
              "dots": false,
              "infinite": true,
              "pauseOnHover": true,
              "speed": 500,
              "slidesToShow": 1,
              "slidesToScroll": 1
          }
      }
    },
    watch: {
        dialog (){
            if(this.dialog == false){
                setTimeout(() => this.$store.dispatch('clearSelectedRoute'), 500);
            }
        }
    },
    computed: {
        route () {
            return this.$store.state.selectedRoute;
        }
    },
    methods: {
        hideRoute (){
            this.dialog = false;
        },
        toggleRoute(id, visible) {
            this.hideRoute(),
                this.$store.dispatch('toggleRoute', { id, visible })
        },
        showPoi(poi){
            this.$store.dispatch('selectMarker', poi);
        },
    }
}
</script>

<style scoped>

</style>
