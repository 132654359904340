<template>
  <div class="home">
    <PointsOfInterest></PointsOfInterest>
  </div>
</template>

<script>
// @ is an alias to /src

import PointsOfInterest from "../components/PointsOfInterest";
export default {
  name: 'Home',
    components: {PointsOfInterest},
}
</script>
