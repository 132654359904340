<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
        >
            <div class="d-flex align-center">
                <a href="https://www.landschaphorstaandemaas.nl" target="_blank" title="Landschap Horst aan de Maas"
                   class="navbar-brand">
                    <img src="./assets/logo.svg" alt="Landschap Horst aan de Maas" width="100"/>
                    <span>Bezoek de website</span>
                </a>
            </div>

            <v-spacer></v-spacer>

        </v-app-bar>

        <v-main>
            <router-view></router-view>
        </v-main>

        <AppInstallDialog></AppInstallDialog>

        <v-bottom-navigation background-color="primary" grow fixed height="60px" dark>

            <v-btn text @click.stop="toggleFilters($event, 'Bikeroutes')" height="60px" :class="{ 'filter-active' : this.$store.state.showFilters && this.$route.meta.category == 'bikeroutes' }">
                <span>Fietsen</span>

                <v-icon>fa fa-biking</v-icon>
            </v-btn>

            <v-btn text @click.stop="toggleFilters($event, 'Walkingroutes')" height="60px" :class="{ 'filter-active' : this.$store.state.showFilters && this.$route.meta.category == 'walkingroutes' }">
                <span>Wandelen</span>

                <v-icon>fa fa-walking</v-icon>
            </v-btn>

            <v-btn text @click.stop="toggleFilters($event, 'Petranroutes')" height="60px" :class="{ 'filter-active' : this.$store.state.showFilters && this.$route.meta.category == 'petranroutes' }">
                <span>Petranpad</span>

                <v-icon>fa fa-route</v-icon>
            </v-btn>

            <v-btn text exact @click.stop="toggleFilters($event, 'Home')" height="60px" :class="{ 'filter-active' : this.$store.state.showFilters && this.$route.meta.category == 'specialplaces' }">
                <span>Mooie plekken</span>

                <v-icon>fa fa-map-marked-alt</v-icon>
            </v-btn>

        </v-bottom-navigation>
    </v-app>
</template>

<script>

import AppInstallDialog from "./components/AppInstallDialog";

export default {
    name: 'App',

    components: {
        AppInstallDialog
    },

    mounted() {
        this.$store.dispatch('initialize', {
            apiUrl: process.env.VUE_APP_API_URL,
            mode: 'app',
            activeRoute: {...this.$route.params, ...this.$route.meta}
        });

    },

    data: () => ({
        category: 'specialplaces'
    }),

    methods: {
        toggleFilters: function($event, $routeName) {

            if(this.$router.currentRoute.name !== $routeName){
                this.$store.commit('TOGGLE_SHOW_FILTERS', {
                    visible: true
                });
                this.$router.push({ name: $routeName});
            }else{
                this.$store.commit('TOGGLE_SHOW_FILTERS', {
                    visible: !this.$store.state.showFilters
                });
            }

        }
    }

};
</script>
<style lang="scss">
@import "assets/scss/app"
</style>
