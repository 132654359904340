var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000px","width":"auto"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",style:({
                'background-color': _vm.marker.color,
                color: '#fff',
                position: 'relative'
            })},[_vm._v(" "+_vm._s(_vm.marker.categoryTitle)+" "),_c('v-btn',{attrs:{"color":"white","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){return _vm.hideMarker()}}},[_c('v-icon',[_vm._v("fa fa-times")])],1)],1),_c('v-card-text',[(_vm.marker.modal)?_c('div',{staticClass:"marker-info-text"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",class:{
                        'col-md-6' : !_vm.marker.text || (_vm.marker.text && _vm.marker.text.length < 1200),
                        'col-md-8' : _vm.marker.text && _vm.marker.text.length >= 1200
                    }},[_c('div',{staticClass:"general-info flex-grow-1 mb-4"},[(_vm.marker.title)?_c('p',{staticClass:"marker-description"},[_vm._v(_vm._s(_vm.marker.title)),(_vm.marker.city)?_c('span',[_vm._v(_vm._s(_vm.marker.city))]):_vm._e()]):_vm._e(),(_vm.marker.info)?_c('div',{staticClass:"mb-3",domProps:{"innerHTML":_vm._s(_vm.marker.info)}}):_vm._e(),(_vm.marker.summary)?_c('p',[_vm._v(_vm._s(_vm.marker.summary))]):_vm._e(),(_vm.marker.text && _vm.marker.text.length < 1200)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.marker.text)}}):_vm._e()]),(_vm.marker.url || _vm.marker.file)?_c('v-row',{staticClass:"buttons flex-grow-0 pt-3"},[_c('v-col',{staticClass:"col-md-6"},[(_vm.marker.file)?_c('v-btn',{attrs:{"color":"primary","href":_vm.marker.file.url,"target":"_blank","depressed":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa fa-file-alt")]),_vm._v(" Brochure")],1):_vm._e()],1)],1):_vm._e()],1),_c('div',{class:{
                        'col-md-6' : !_vm.marker.text || (_vm.marker.text && _vm.marker.text.length < 1200),
                        'col-md-4' : _vm.marker.text && _vm.marker.text.length >= 1200
                    }},[(_vm.marker.images && _vm.marker.images.length > 0)?_c('div',{staticClass:"images"},[_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.sliderSettings,false),_vm._l((_vm.marker.images),function(image){return _c('a',{key:image.id,attrs:{"href":image.largeUrl,"data-fancybox":"marker","data-thumb":image.url}},[_c('img',{attrs:{"src":image.url}})])}),0)],1):_vm._e()])],1),(_vm.marker.text && _vm.marker.text.length >= 1200)?_c('div',{staticClass:"extended-text",domProps:{"innerHTML":_vm._s(_vm.marker.text)}}):_vm._e(),_c('v-flex',{staticClass:"mt-5"},[_c('v-btn',{attrs:{"color":"primary","target":"_blank","href":'https://www.landschaphorstaandemaas.nl/' + _vm.marker.websiteSlug,"depressed":""}},[_vm._v("Alle informatie"),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fa fa-chevron-right")])],1)],1)],1):_c('div',{staticClass:"info-window-loading"},[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v("Bezig met laden... ")])]),(_vm.marker.id)?_c('div',{staticClass:"poi-id"},[_vm._v("#"+_vm._s(_vm.marker.id))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }