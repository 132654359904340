var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-1","size":"xl","hide-header":"","hide-footer":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"headline",style:({
                'background-color': _vm.marker.color,
                color: '#fff',
                position: 'relative'
            })},[_vm._v(" "+_vm._s(_vm.marker.categoryTitle)+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.hideMarker()}}},[_c('i',{staticClass:"fa fa-times"})])]),(_vm.marker.modal)?_c('div',{staticClass:"marker-info-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex flex-column",class:{
                'col-md-6' : !_vm.marker.text || (_vm.marker.text && _vm.marker.text.length < 1200),
                'col-md-8' : _vm.marker.text && _vm.marker.text.length >= 1200
            }},[_c('div',{staticClass:"general-info flex-grow-1"},[(_vm.marker.title)?_c('p',{staticClass:"marker-description"},[_vm._v(_vm._s(_vm.marker.title)),(_vm.marker.city)?_c('span',[_vm._v(_vm._s(_vm.marker.city))]):_vm._e()]):_vm._e(),(_vm.marker.info)?_c('div',{staticClass:"mb-3",domProps:{"innerHTML":_vm._s(_vm.marker.info)}}):_vm._e(),(_vm.marker.summary)?_c('p',[_vm._v(_vm._s(_vm.marker.summary))]):_vm._e(),(_vm.marker.text && _vm.marker.text.length < 1200)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.marker.text)}}):_vm._e()]),(_vm.marker.url || _vm.marker.file)?_c('div',{staticClass:"row buttons flex-grow-0 pt-3"},[_c('div',{staticClass:"col-md-6"},[(_vm.marker.file)?_c('a',{staticClass:"btn btn-primary",attrs:{"color":"primary","href":_vm.marker.file.url,"target":"_blank"}},[_c('i',{staticClass:"fa fa-file-alt mr-2 fa-sm"}),_vm._v(" Brochure")]):_vm._e()])]):_vm._e()]),_c('div',{class:{
                'col-md-6' : !_vm.marker.text || (_vm.marker.text && _vm.marker.text.length < 1200),
                'col-md-4' : _vm.marker.text && _vm.marker.text.length >= 1200
            }},[(_vm.marker.images && _vm.marker.images.length > 0)?_c('div',{staticClass:"images"},[_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.sliderSettings,false),_vm._l((_vm.marker.images),function(image){return _c('a',{key:image.id,attrs:{"href":image.largeUrl,"data-fancybox":"marker","data-thumb":image.url}},[_c('img',{attrs:{"src":image.url}})])}),0)],1):_vm._e()])]),(_vm.marker.text && _vm.marker.text.length >= 1200)?_c('div',{staticClass:"extended-text",domProps:{"innerHTML":_vm._s(_vm.marker.text)}}):_vm._e(),_c('div',{staticClass:"website-button pt-5 d-flex justify-content-end"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":'https://www.landschaphorstaandemaas.nl/' + _vm.marker.websiteSlug}},[_vm._v("Alle informatie "),_c('i',{staticClass:"fa fa-chevron-right ml-2 fa-sm"})])])]):_c('div',{staticClass:"info-window-loading"},[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v("Bezig met laden... ")]),(_vm.marker.id)?_c('div',{staticClass:"poi-id"},[_vm._v("#"+_vm._s(_vm.marker.id))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }